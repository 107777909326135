<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">
          <span>总数/条: {{ total }}</span>
          <span style="margin-left: 20px">合计金额/分: {{ totalAmount }}</span>
        </label>
      </div>
      <div class="condition-item">
        <label class="fn-14">学生姓名</label>
        <el-input v-model="studentName" placeholder="请输入学生姓名" autocomplete="off" style="width: 160px; " size="small">
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">日期</label>
        <el-date-picker v-model="times" align="right" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
          placeholder="选择开始日期" autocomplete="off" size="small" style="width: 180px">
        </el-date-picker>
        <label class="fn-14" style="padding: 0 10px">-</label>
        <el-date-picker v-model="timesEnd" align="right" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
          placeholder="选择结束日期" autocomplete="off" size="small" style="width: 180px">
        </el-date-picker>
      </div>

      <div class="condition-item">
        <el-button type="primary" style="margin-left: 10px" size="small" @click.native="selectData">查询</el-button>
        <el-button type="info" icon="el-icon-refresh" @click="resetSearch" size="small">重置
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table :data="orderDetailTableData" border style="width: 100%;" height="672">
        <el-table-column prop="payId" label="支付编号" width="450"></el-table-column>
        <el-table-column prop="icbcVerifyNo" label="客户实名认证编号" width="300">
        </el-table-column>
        <el-table-column prop="studentName" label="学生姓名" width="150"></el-table-column>
        <el-table-column prop="recvAccNo" label="收款账户编号"  width="450">
        </el-table-column>
        <el-table-column prop="amount" label="金额/分" width="100"></el-table-column>
        <el-table-column prop="workTime" label="最终交易时间" width="200">
        </el-table-column>
        <el-table-column prop="workDate" label="对账日期" width="200"></el-table-column>
        <el-table-column prop="mergenPaymentFlag" label="是否合并支付" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.mergenPaymentFlag === '1'" type="success">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="mergenPaymentNo" label="合并支付编号"></el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: right">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryBatchCheck,
  queryBatchCheckTotal,
} from "../../../api/batchCheck.js";
import { dateFormat } from "../../../utils/date.js";

export default {
  name: "Reconciliation",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      times: "",
      timesEnd: "",
      studentName: "",
      orderDetailTableData: [],
      total: 0,
      totalAmount: 0,
      currentPage: 1,
      pageSize: 20,
    };
  },
  created() {
    var yestday = new Date();
    yestday.setTime(yestday.getTime() - 24 * 60 * 60 * 1000);
    this.times = dateFormat("YYYY-mm-dd", yestday);
    this.timesEnd = dateFormat("YYYY-mm-dd", yestday);
    this.selectData();
  },
  methods: {
    selectData() {
      const datas = {
        stime: this.times,
        etime: this.timesEnd,
        studentName: this.studentName
      };
      this.tableLoading = true;
      queryBatchCheck(this.currentPage, this.pageSize, datas)
        .then((resp) => {
          this.orderDetailTableData = resp.data.content;
          this.total = resp.data.totalElements;
          this.tableLoading = false;
        })
        .catch((e) => { });
      queryBatchCheckTotal(this.currentPage, this.pageSize, datas)
        .then((resp) => {
          this.totalAmount = resp.data.total || 0;
        })
        .catch((e) => { });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.selectData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectData();
    },
    resetSearch() {
      var yestday = new Date();
      yestday.setTime(yestday.getTime() - 24 * 60 * 60 * 1000);
      this.times = dateFormat("YYYY-mm-dd", yestday);
      this.timesEnd = dateFormat("YYYY-mm-dd", yestday);
      this.studentName = ""
      this.selectData();
    },
  },
};
</script>

<style scope>
.condition-item {
  margin-right: 10px;
}
</style>